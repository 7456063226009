this["Templates"] = this["Templates"] || {};

Handlebars.registerPartial("header/_header-user-menu-item", this["Templates"]["header/_header-user-menu-item"] = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, functionType="function", escapeExpression=this.escapeExpression;


  buffer += "<div class=\"kx-menu-button\">\n<a class=\"kx-menu-item-lnk ";
  if (helper = helpers.buttonClassName) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.buttonClassName); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\"></a>\n</div>\n<div class=\"kx-notify-count\">\n\n</div>\n<div class=\"kx-megaflyout ";
  if (helper = helpers.flyoutClassName) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.flyoutClassName); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\">\n<div class=\"kx-megaflyout-inner\">\n<div class=\"kx-user-menu-item-content\"></div>\n</div>\n</div>\n";
  return buffer;
  }));

this["Templates"]["auth/banned_dialog/banned_dialog"] = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, options, helperMissing=helpers.helperMissing, functionType="function", escapeExpression=this.escapeExpression;


  buffer += "<div class=\"kx-header-bar\"><h2>";
  stack1 = (helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "auth.bannedDialog.dialogHeading", options) : helperMissing.call(depth0, "i18n", "auth.bannedDialog.dialogHeading", options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "</h2></div>\r\n\r\n<div class=\"kx-ban-message\">\r\n<h2>";
  stack1 = (helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "auth.bannedDialog.bannedMessage", options) : helperMissing.call(depth0, "i18n", "auth.bannedDialog.bannedMessage", options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "</h2>\r\n";
  stack1 = (helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "auth.bannedDialog.supportMessage", options) : helperMissing.call(depth0, "i18n", "auth.bannedDialog.supportMessage", options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\r\n\r\n<div class=\"kx-ban-details\">\r\n<div id=\"kx-ban-copy-button\" class=\"kx-button kx-alt3\" title=\"";
  stack1 = (helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "auth.bannedDialog.copyToClipboard", options) : helperMissing.call(depth0, "i18n", "auth.bannedDialog.copyToClipboard", options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\">\r\n";
  stack1 = (helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "auth.bannedDialog.copy", options) : helperMissing.call(depth0, "i18n", "auth.bannedDialog.copy", options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\r\n</div>\r\n<ul>\r\n<li>id: <span>"
    + escapeExpression(((stack1 = ((stack1 = (depth0 && depth0.message)),stack1 == null || stack1 === false ? stack1 : stack1.id)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "</span></li>\r\n<li>aggregateId: <span>"
    + escapeExpression(((stack1 = ((stack1 = (depth0 && depth0.message)),stack1 == null || stack1 === false ? stack1 : stack1.aggregateId)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "</span></li>\r\n</ul>\r\n</div>\r\n</div>\n";
  return buffer;
  });

this["Templates"]["common/components/auth_modal/forgot_password/forgot_password"] = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;


  buffer += "<div id=\"kx-forgot-email-input-region\" class=\"kx-auth-modal-forgot-email\"></div>\n<div class=\"kx-auth-modal-forgot-text\">\n"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "auth_modal.forgotPassword.enterYourEmail", options) : helperMissing.call(depth0, "i18n", "auth_modal.forgotPassword.enterYourEmail", options)))
    + "\n</div>\n<div id=\"kx-auth-modal-submit-button-region\"></div>\n";
  return buffer;
  });

this["Templates"]["common/components/auth_modal/login/login"] = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;


  buffer += "<div class=\"kx-auth-modal-form-container\">\n<div class=\"kx-auth-modal-form-labels login\">\n<div> "
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "auth_modal.login.username", options) : helperMissing.call(depth0, "i18n", "auth_modal.login.username", options)))
    + " </div>\n<div> "
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "auth_modal.login.password", options) : helperMissing.call(depth0, "i18n", "auth_modal.login.password", options)))
    + " </div>\n</div>\n<div class=\"kx-auth-modal-form-inputs\">\n<div class=\"kx-auth-modal-login-message\"></div>\n<div id=\"username\"></div>\n<div id=\"password\"></div>\n<div class=\"kx-auth-modal-forgot-password\">\n"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "auth_modal.login.forgotPassword", options) : helperMissing.call(depth0, "i18n", "auth_modal.login.forgotPassword", options)))
    + "\n</div>\n</div>\n</div>\n<div id=\"kx-auth-modal-login-button-region\"></div>\n<div class=\"kx-auth-modal-text\">\n<div class=\"kx-auth-modal-or\">\n"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "auth_modal.login.or", options) : helperMissing.call(depth0, "i18n", "auth_modal.login.or", options)))
    + "\n</div>\n<div>\n"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "auth_modal.login.loginWithThirdParty", options) : helperMissing.call(depth0, "i18n", "auth_modal.login.loginWithThirdParty", options)))
    + "\n</div>\n</div>\n<div class=\"kx-auth-modal-icons\">\n<div id=\"loginFacebook\" class=\"icons facebook\"></div>\n<div id=\"loginGoogle\" class=\"icons google\"></div>\n<div id=\"loginSteam\" class=\"icons steam\"></div>\n</div>\n<div class=\"kx-auth-fine-print\">\n"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "auth_modal.login.regFinePrint", options) : helperMissing.call(depth0, "i18n", "auth_modal.login.regFinePrint", options)))
    + " <span class=\"kx-tos\">"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "auth_modal.login.termsOfService", options) : helperMissing.call(depth0, "i18n", "auth_modal.login.termsOfService", options)))
    + "</span>\n</div>\n";
  return buffer;
  });

this["Templates"]["common/components/auth_modal/recaptcha/recaptcha"] = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<div id=\"kx-auth-modal-recaptcha-region\"></div>\n<div id=\"kx-auth-modal-continue-button-region\"></div>\n<div class=\"kx_regn_captcha_validation_msg error-messages\"></div>\n";
  });

this["Templates"]["common/components/auth_modal/signup/signup"] = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;


  buffer += "<div class=\"kx-auth-modal-form-container\">\n<div class=\"kx-auth-modal-form-labels\">\n<div> "
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "auth_modal.signup.username", options) : helperMissing.call(depth0, "i18n", "auth_modal.signup.username", options)))
    + " </div>\n<div> "
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "auth_modal.signup.email", options) : helperMissing.call(depth0, "i18n", "auth_modal.signup.email", options)))
    + " </div>\n<div> "
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "auth_modal.signup.password", options) : helperMissing.call(depth0, "i18n", "auth_modal.signup.password", options)))
    + " </div>\n</div>\n<div class=\"kx-auth-modal-form-inputs\">\n<div class=\"kx-auth-modal-signup-message\"></div>\n<div id=\"username\"></div>\n<div id=\"email\"></div>\n<div id=\"password\"></div>\n<div id=\"termsCheckbox\"></div>\n<div id=\"policyCheckbox\"></div>\n</div>\n</div>\n<div id=\"kx-auth-modal-signup-button-region\"></div>\n<div class=\"kx-auth-modal-text\">\n<div class=\"kx-auth-modal-or\">\n"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "auth_modal.signup.or", options) : helperMissing.call(depth0, "i18n", "auth_modal.signup.or", options)))
    + "\n</div>\n<div>\n"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "auth_modal.signup.registerWithThirdParty", options) : helperMissing.call(depth0, "i18n", "auth_modal.signup.registerWithThirdParty", options)))
    + "\n</div>\n</div>\n<div class=\"kx-auth-modal-icons\">\n<div id=\"signupFacebook\" class=\"icons facebook\"></div>\n<div id=\"signupGoogle\" class=\"icons google\"></div>\n<div id=\"signupSteam\" class=\"icons steam\"></div>\n</div>\n<div class=\"kx-auth-fine-print\">\n"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "auth_modal.signup.regFinePrint", options) : helperMissing.call(depth0, "i18n", "auth_modal.signup.regFinePrint", options)))
    + " <span class=\"kx-tos\">"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "auth_modal.signup.termsOfService", options) : helperMissing.call(depth0, "i18n", "auth_modal.signup.termsOfService", options)))
    + "</span>\n</div>\n";
  return buffer;
  });

this["Templates"]["common/components/confirm_dialog/confirm-dialog"] = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, functionType="function", escapeExpression=this.escapeExpression, self=this;

function program1(depth0,data) {
  
  
  return "\n<div class=\"col-md-3 kx-confirm-dialog-button-wrapper\">\n<div class=\"kx-confirm-dialog-cancel\"></div>\n</div>\n";
  }

function program3(depth0,data) {
  
  
  return "3";
  }

function program5(depth0,data) {
  
  
  return "6";
  }

  buffer += "<div class=\"kx-dialog-veil\"></div>\n<div class=\"kx-dialog-pane\">\n<div class=\"kx-dialog-pane-inner\">\n<div class=\"kx-dialog-pane-content kx-clearfix\">\n<div class=\"row kx-confirm-dialog-header kx-header-bar\">\n<div class=\"col-md-1\"></div>\n<div class=\"col-md-10\">";
  if (helper = helpers.title) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.title); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</div>\n<div class=\"col-md-1\"></div>\n</div>\n<div class=\"row\">\n<div class=\"col-md-1\"></div>\n<div class=\"col-md-10 kx-confirm-dialog-msg-wrapper\">\n<div class=\"kx-confirm-dialog-msg\">";
  if (helper = helpers.message) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.message); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</div>\n</div>\n<div class=\"col-md-1\"></div>\n</div>\n<div class=\"row kx-cta-group\">\n<div class=\"col-md-3\"></div>\n";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.showCancelButton), {hash:{},inverse:self.noop,fn:self.program(1, program1, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n<div class=\"col-md-";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.showCancelButton), {hash:{},inverse:self.program(5, program5, data),fn:self.program(3, program3, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += " kx-confirm-dialog-button-wrapper\">\n<div class=\"kx-confirm-dialog-confirm\"></div>\n</div>\n<div class=\"col-md-3\"></div>\n</div>\n</div>\n<div class=\"kx-dialog-close-button\" style=\"display:none;\"></div>\n</div>\n</div>\n";
  return buffer;
  });

this["Templates"]["common/components/dialog/dialog"] = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<div class=\"kx-dialog-veil\"></div>\n<div class=\"kx-dialog-pane\">\n<div class=\"kx-dialog-pane-inner\">\n<div class=\"kx-dialog-pane-content kx-clearfix\"></div>\n<div class=\"kx-dialog-close-button\" style=\"display:none;\"></div>\n</div>\n</div>\n";
  });

this["Templates"]["cookie_notice/cookie-notice"] = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<div class=\"cookie-notice-banner\">\n<img class=\"cookie-notice-close\" src=\"/modules/cookie_notice/images/close-button.png\" alt=\"Close\" />\n<p>We use cookies on this site to improve your experience. By using this site,\nyou consent to the placement of these cookies on your computer. Click\n<a href=\"https://corp.kixeye.com/pp.html\" target=\"_blank\">here</a> for more information.</p>\n</div>\n";
  });

this["Templates"]["footer/footer"] = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";


  buffer += "<div class=\"container\">\n<div class=\"row\">\n<div class=\"col-md-3\">\n<div class=\"locales-region\"></div>\n<div class=\"kx-footer-logo\"></div>\n</div>\n<div class=\"col-md-3\">\n<div class=\"kx-footer-kixeye\">\n<h3>"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "footer.kixeye", options) : helperMissing.call(depth0, "i18n", "footer.kixeye", options)))
    + "</h3>\n<ul>\n<li><a href=\"https://www.kixeye.com/support\" target=\"_blank\">"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "footer.support", options) : helperMissing.call(depth0, "i18n", "footer.support", options)))
    + "</a></li>\n<li><a class=\"oldgroups\" href=\"/forum/groups\">"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "footer.oldgroups", options) : helperMissing.call(depth0, "i18n", "footer.oldgroups", options)))
    + "</a></li>\n</ul>\n</div>\n</div>\n<div class=\"col-md-3\">\n<div class=\"kx-footer-corp\">\n<h3>"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "footer.corporate", options) : helperMissing.call(depth0, "i18n", "footer.corporate", options)))
    + "</h3>\n<ul>\n<li><a href=\"http://corp.kixeye.com\" target=\"_blank\">"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "footer.aboutUs", options) : helperMissing.call(depth0, "i18n", "footer.aboutUs", options)))
    + "</a></li>\n<li><a href=\"http://corp.kixeye.com/#careers\" target=\"_blank\">"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "footer.careers", options) : helperMissing.call(depth0, "i18n", "footer.careers", options)))
    + "</a></li>\n</ul>\n</div>\n</div>\n<div class=\"col-md-3\">\n<div class=\"kx-footer-social\">\n<h3>"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "footer.socialConnect", options) : helperMissing.call(depth0, "i18n", "footer.socialConnect", options)))
    + "</h3>\n<ul>\n<li><a class=\"kx-footer-social-facebook\" href=\"https://www.facebook.com/kixeye\" target=\"_blank\">Facebook</a></li>\n<li><a class=\"kx-footer-social-twitter\" href=\"https://twitter.com/KIXEYE\" target=\"_blank\">Twitter</a></li>\n</ul>\n</div>\n</div>\n</div>\n<div class=\"row\">\n<div class=\"col-md-12\">\n<a href=\"https://corp.kixeye.com/legal.html\" target=\"_blank\" class=\"termsofservice\">"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "footer.termsOfService", options) : helperMissing.call(depth0, "i18n", "footer.termsOfService", options)))
    + "</a> |\n<a href=\"https://corp.kixeye.com/pp.html\" target=\"_blank\" class=\"privacypolicy\">"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "footer.privacyPolicy", options) : helperMissing.call(depth0, "i18n", "footer.privacyPolicy", options)))
    + " [<span>"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "footer.updated", options) : helperMissing.call(depth0, "i18n", "footer.updated", options)))
    + "</span>]</a> &\n<a href=\"https://corp.kixeye.com/cookie-policy.html\" target=\"_blank\" class=\"cookiepolicy\">"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "footer.cookiePolicy", options) : helperMissing.call(depth0, "i18n", "footer.cookiePolicy", options)))
    + "</a>\n<div class=\"copyright\">\n&copy; ";
  if (helper = helpers.currentYear) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.currentYear); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + " "
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "footer.kixeyeInc", options) : helperMissing.call(depth0, "i18n", "footer.kixeyeInc", options)))
    + " "
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "footer.allRightsReserved", options) : helperMissing.call(depth0, "i18n", "footer.allRightsReserved", options)))
    + "\n</div>\n</div>\n</div>\n</div>\n";
  return buffer;
  });

this["Templates"]["header/header-account-info-ribbon"] = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;


  buffer += "<div class=\"kx-account-info-ribbon-wrapper kx-menu-wrapper\">\n<div class=\"kx-flyout-account-info\">\n<div class=\"kx-account-info-inner\">\n<ul class=\"kx-menu\">\n<li class=\"kx-menu-label\">\n"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "header.language", options) : helperMissing.call(depth0, "i18n", "header.language", options)))
    + "\n</li>\n<li class=\"kx-menu-item\">\n<div class=\"kx-header-locales-region\"></div>\n</li>\n<li class=\"kx-menu-item\">\n<a class=\"kx-menu-item-lnk kx-settings-btn\">"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "header.settings", options) : helperMissing.call(depth0, "i18n", "header.settings", options)))
    + "</a>\n</li>\n<li class=\"kx-menu-separator\">\n|\n</li>\n<li class=\"kx-menu-item\">\n<a class=\"kx-menu-item-lnk kx-header-logout-btn\">"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "header.logout", options) : helperMissing.call(depth0, "i18n", "header.logout", options)))
    + "</a>\n</li>\n</ul>\n</div>\n</div>\n</div>\n";
  return buffer;
  });

this["Templates"]["header/header-account-info"] = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, functionType="function", escapeExpression=this.escapeExpression;


  buffer += "<div class=\"kx-account-info-menu-wrapper kx-menu-wrapper\">\n<div class=\"kx-main-menu-btn\">\n<span class=\"kx-header-account\">\n<div class=\"kx-header-account-avatar\"></div>\n<div class=\"kx-account-right-col\">\n<div class=\"kx-account-text-wrapper\">\n<div class=\"kx-header-account-name kx-ellipsis\">"
    + escapeExpression(((stack1 = ((stack1 = (depth0 && depth0.user)),stack1 == null || stack1 === false ? stack1 : stack1.username)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "</div>\n</div>\n</div>\n</span>\n<div class=\"kx-nux-achievement-bar-btn-region\" style=\"display: none;\"></div>\n<div class=\"kx-menu-btn-open\"></div>\n</div>\n</div>\n";
  return buffer;
  });

this["Templates"]["header/header-context-change"] = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;


  buffer += "<span>"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{
    'currentContextName': ((depth0 && depth0.currentContextName)),
    'newContextName': ((depth0 && depth0.newContextName))
  },data:data},helper ? helper.call(depth0, "header.context.change", options) : helperMissing.call(depth0, "i18n", "header.context.change", options)))
    + "</span>\n<div class=\"kx-btn-group\">\n<div class=\"kx-cancel-region\"></div>\n<div class=\"kx-continue-region\"></div>\n</div>\n";
  return buffer;
  });

this["Templates"]["header/header-context-nav-item"] = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, options, functionType="function", helperMissing=helpers.helperMissing;


  buffer += "<a href=\"";
  if (helper = helpers.url) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.url); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\">";
  stack1 = (helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.text), options) : helperMissing.call(depth0, "i18n", (depth0 && depth0.text), options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "</a><span class=\"kx-context-item-close\"></span>\n";
  return buffer;
  });

this["Templates"]["header/header-context-nav"] = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<span class=\"kx-left-edge\"></span>\n<div class=\"kx-context-icon\"></div>\n<ul class=\"kx-context-nav-items\"></ul>\n<span class=\"kx-right-edge\"></span>\n";
  });

this["Templates"]["header/header-login-join"] = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, options, helperMissing=helpers.helperMissing;


  buffer += "<div class=\"kx-header-join-btn\">\n<span>";
  stack1 = (helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "header.joinnow", options) : helperMissing.call(depth0, "i18n", "header.joinnow", options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "</span>\n</div>\n<div class=\"kx-header-login-btn\">\n<span class=\"kx-menu-btn-text\">\n";
  stack1 = (helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "header.login", options) : helperMissing.call(depth0, "i18n", "header.login", options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n</span>\n</div>\n";
  return buffer;
  });

this["Templates"]["header/header-nav-communitylist-item"] = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, functionType="function", escapeExpression=this.escapeExpression;


  buffer += "<a class=\"";
  if (helper = helpers.className) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.className); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\" href=\"";
  if (helper = helpers.href) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.href); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\">";
  if (helper = helpers.text) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.text); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</a>\n";
  return buffer;
  });

this["Templates"]["header/header-nav-communitylist"] = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";


  buffer += "<div class=\"kx-nav-ribbon-communitylist-header\">"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "header.community", options) : helperMissing.call(depth0, "i18n", "header.community", options)))
    + "</div>\n<ul class=\"kx-submenu-flyout ";
  if (helper = helpers.commonName) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.commonName); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\"></ul>\n";
  return buffer;
  });

this["Templates"]["header/header-nav-corplist-item"] = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, functionType="function", escapeExpression=this.escapeExpression;


  buffer += "<a class=\"";
  if (helper = helpers.className) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.className); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\" href=\"";
  if (helper = helpers.href) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.href); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\" target=\"";
  if (helper = helpers.target) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.target); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\">";
  if (helper = helpers.text) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.text); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</a>\n";
  return buffer;
  });

this["Templates"]["header/header-nav-corplist"] = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";


  buffer += "<div class=\"kx-nav-ribbon-corplist-header\">"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "header.corporate", options) : helperMissing.call(depth0, "i18n", "header.corporate", options)))
    + "</div>\n<ul class=\"kx-submenu-flyout ";
  if (helper = helpers.commonName) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.commonName); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\"></ul>\n";
  return buffer;
  });

this["Templates"]["header/header-nav-gamelist-desktop"] = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;


  buffer += "<div class=\"kx-nav-ribbon-gamelist-header\">"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "header.desktopGames", options) : helperMissing.call(depth0, "i18n", "header.desktopGames", options)))
    + "</div>\n<ul class=\"kx-nav-ribbon-gamelist-list\"></ul>\n";
  return buffer;
  });

this["Templates"]["header/header-nav-gamelist-item"] = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, functionType="function", escapeExpression=this.escapeExpression;


  buffer += "<span class=\"kx-nav-ribbon-gamelist-list-icon ";
  if (helper = helpers.id) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.id); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\"></span>\n<a class=\"kx-nav-ribbon-gamelist-list-link\" href=\"/game/";
  if (helper = helpers.url) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.url); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "/home\">";
  if (helper = helpers.name) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.name); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "</a>\n";
  return buffer;
  });

this["Templates"]["header/header-nav-gamelist-mobile"] = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;


  buffer += "<div class=\"kx-nav-ribbon-gamelist-header\">"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "header.mobileGames", options) : helperMissing.call(depth0, "i18n", "header.mobileGames", options)))
    + "</div>\n<ul class=\"kx-nav-ribbon-gamelist-list\"></ul>\n";
  return buffer;
  });

this["Templates"]["header/header-nav-gamelist"] = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<div class=\"kx-nav-ribbon-gamelist-platform\">\n<div class=\"kx-nav-ribbon-gamelist-links-region\"></div>\n</div>\n";
  });

this["Templates"]["header/header-nav-ribbon"] = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<div class=\"kx-nav-ribbon-overlay-region\"></div>\n<div class=\"kx-nav-ribbon-desktopgamelist-region\"></div>\n<div class=\"kx-nav-ribbon-mobilegamelist-region\"></div>\n<div class=\"kx-nav-ribbon-grouplist-region\"></div>\n<div class=\"kx-nav-ribbon-communitylist-region\"></div>\n<div class=\"kx-nav-ribbon-corplist-region\"></div>\n<div class=\"kx-divider-horizontal\">&nbsp;</div>\n";
  });

this["Templates"]["header/header-nav-toggle"] = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;


  buffer += "<div class=\"kx-main-menu-btn\">\n<span>"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "header.menu", options) : helperMissing.call(depth0, "i18n", "header.menu", options)))
    + "</span>\n</div>\n";
  return buffer;
  });

this["Templates"]["header/header-simple-ribbon"] = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, functionType="function", escapeExpression=this.escapeExpression;


  buffer += "<div class=\"kx-simple-ribbon-wrapper\">\n<div class=\"kx-simple-ribbon-text\">";
  if (helper = helpers.message) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.message); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</div>\n<div class=\"kx-simple-ribbon-arrow\"></div>\n</div>\n";
  return buffer;
  });

this["Templates"]["header/header"] = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, functionType="function", self=this;

function program1(depth0,data) {
  
  
  return "\n<li class=\"kx-user-chats-wrapper\"></li>\n";
  }

  buffer += "<div class=\"kx-header-logo\">\n<img src=\"";
  stack1 = ((stack1 = ((stack1 = (depth0 && depth0.config)),stack1 == null || stack1 === false ? stack1 : stack1.STATIC_BASE_URL)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1);
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "/modules/header/images/kixeye-logo.png\" width=\"150\" height=\"29\" alt=\"KIXEYE\" />\n</div>\n<div class=\"kx-header-players-online-region\"></div>\n<div class=\"kx-header-nav-toggle-region\"></div>\n<div class=\"kx-header-context-nav\"></div>\n<div class=\"kx-header-right-col\">\n<div class=\"kx-searchbar-region\"></div>\n<div class=\"kx-header-user-controls-region kx-clearfix\"></div>\n<div class=\"kx-header-sub-divider\"></div>\n<div class=\"kx-user-menu-wrapper kx-clearfix\">\n<ul class=\"kx-menu\">\n<li class=\"kx-user-friends-wrapper\"></li>\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 && depth0.switches)),stack1 == null || stack1 === false ? stack1 : stack1.chatEnabled), {hash:{},inverse:self.noop,fn:self.program(1, program1, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n<li class=\"kx-user-notifications-wrapper\"></li>\n</ul>\n</div>\n</div>\n<div class=\"kx-divider-horizontal\">&nbsp;</div>\n<div class=\"kx-header-nav-ribbon-region\"></div>\n<div class=\"kx-header-bottom\">\n<div class=\"kx-banners-region\"></div>\n</div>\n";
  return buffer;
  });

this["Templates"]["home/home-logged-out"] = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;


  buffer += "<div class=\"kx-home-masthead\"></div>\n<div class=\"kx-home-pager\"></div>\n<div class=\"container\">\n<div class=\"row\">\n<div class=\"col-md-9\">\n<div class=\"kx-home-games-container\">\n<div class=\"kx-home-static-games\"></div>\n</div>\n</div>\n<div class=\"col-md-3\">\n<h2>"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "home.joinNow", options) : helperMissing.call(depth0, "i18n", "home.joinNow", options)))
    + "</h2>\n<div class=\"kx-home-game-signup\"></div>\n</div>\n</div>\n</div>\n";
  return buffer;
  });

this["Templates"]["inbox/inbox-actions"] = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;


  buffer += "<div class=\"inbox-arrow-up\"></div>\n<div class=\"start-thread kx-button kx-alt1\">"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "inbox.startNewThread", options) : helperMissing.call(depth0, "i18n", "inbox.startNewThread", options)))
    + "</div>\n";
  return buffer;
  });

this["Templates"]["inbox/inbox-empty-inbox"] = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;


  buffer += "<div class=\"kx-empty\">\n"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "inbox.emptyInbox", options) : helperMissing.call(depth0, "i18n", "inbox.emptyInbox", options)))
    + "\n</div>\n";
  return buffer;
  });

this["Templates"]["inbox/inbox-message"] = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";


  buffer += "<div class=\"kx-message-avatar\"></div>\n<div class=\"kx-text-body\">\n<p class=\"kx-body\">"
    + escapeExpression((helper = helpers.chain || (depth0 && depth0.chain),options={hash:{},data:data},helper ? helper.call(depth0, "htmlDecode", "nl2br", (depth0 && depth0.body), options) : helperMissing.call(depth0, "chain", "htmlDecode", "nl2br", (depth0 && depth0.body), options)))
    + "</p>\n<p class=\"kx-time\">";
  if (helper = helpers.date) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.date); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</p>\n</div>\n";
  return buffer;
  });

this["Templates"]["inbox/inbox-no-thread-selected"] = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;


  buffer += "<div class=\"kx-empty kx-hidden\">\n"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "inbox.noThreadSelected", options) : helperMissing.call(depth0, "i18n", "inbox.noThreadSelected", options)))
    + "\n</div>\n";
  return buffer;
  });

this["Templates"]["inbox/inbox-panel-empty"] = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;


  buffer += "<div class=\"kx-megaflyout-panel-empty-indicator\">"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "inbox.panel.empty", options) : helperMissing.call(depth0, "i18n", "inbox.panel.empty", options)))
    + "</div>\n";
  return buffer;
  });

this["Templates"]["inbox/inbox-panel-layout"] = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;


  buffer += "<div class=\"kx-heading\">\n<div class=\"kx-cta-group\"></div>\n<div class=\"kx-heading-title\">\n<span>"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "inbox.inboxTitle", options) : helperMissing.call(depth0, "i18n", "inbox.inboxTitle", options)))
    + "</span>\n</div>\n</div>\n<div class=\"kx-settings-region\"></div>\n<div class=\"kx-inbox-deprecated-notice\">\n<span class=\"kx-notice\"></span><p>"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "inbox.deprecatedNotice", options) : helperMissing.call(depth0, "i18n", "inbox.deprecatedNotice", options)))
    + "</p>\n<a href=\"/forum/discussion/498957/p1\">"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "inbox.deprecatedMoreDetails", options) : helperMissing.call(depth0, "i18n", "inbox.deprecatedMoreDetails", options)))
    + "</a>\n</div>\n<div class=\"kx-items-list-region\"></div>\n<div class=\"kx-footing\">"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "inbox.seeAll", options) : helperMissing.call(depth0, "i18n", "inbox.seeAll", options)))
    + "</div>\n<div class=\"kx-loading-region\">\n<div class=\"kx-more-loading\"></div>\n</div>\n";
  return buffer;
  });

this["Templates"]["inbox/inbox-panel-message-item"] = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, options, functionType="function", escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing;


  buffer += "<div class=\"kx-unread-icon\"></div>\n<div class=\"kx-icon kx-avatar-container kx-avatar\"></div>\n<div class=\"kx-content\">\n<div class=\"kx-title kx-ellipsis\">";
  if (helper = helpers.title) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.title); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</div>\n<div class=\"kx-message-body\">";
  if (helper = helpers.snippet) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.snippet); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "</div>\n</div>\n<div class=\"kx-cta-wrapper\">\n<div class=\"kx-cta-button-wrapper\">\n<div class=\"kx-cta-button kx-button\">"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "inbox.panel.cta", options) : helperMissing.call(depth0, "i18n", "inbox.panel.cta", options)))
    + "</div>\n</div>\n</div>\n";
  return buffer;
  });

this["Templates"]["inbox/inbox-participants"] = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "\n";
  });

this["Templates"]["inbox/inbox-thread-item"] = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, functionType="function", escapeExpression=this.escapeExpression;


  buffer += "<div class=\"kx-avatar-container kx-avatar\"></div>\n<div class=\"kx-message-summary\">\n<div class=\"kx-edit-menu\"></div>\n<h5 class=\"kx-ellipsis\">";
  if (helper = helpers.title) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.title); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</h5>\n<p class=\"kx-ellipsis\">";
  if (helper = helpers.dateTimestamp) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.dateTimestamp); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</p>\n</div>\n";
  return buffer;
  });

this["Templates"]["inbox/inbox-thread-list"] = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;


  buffer += "<h2>"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "inbox.inboxTitle", options) : helperMissing.call(depth0, "i18n", "inbox.inboxTitle", options)))
    + "</h2>\n<div class=\"thread-list\">\n<div class=\"threads\"></div>\n</div>\n";
  return buffer;
  });

this["Templates"]["inbox/inbox-thread"] = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, options, functionType="function", escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing;


  buffer += "<h2>";
  if (helper = helpers.title) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.title); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</h2>\n<div class=\"messages\"></div>\n<div class=\"message-compose-region\">"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "inbox.replyDeprecateMessage", options) : helperMissing.call(depth0, "i18n", "inbox.replyDeprecateMessage", options)))
    + "</div>\n";
  return buffer;
  });

this["Templates"]["inbox/inbox"] = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;


  buffer += "<div class=\"container\">\n<div class=\"row\">\n<div class=\"kx-inbox-deprecated-notice\">\n<span class=\"kx-notice\"></span>"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "inbox.deprecatedNotice", options) : helperMissing.call(depth0, "i18n", "inbox.deprecatedNotice", options)))
    + " <a href=\"/forum/discussion/498957/p1\">"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "inbox.deprecatedMoreDetails", options) : helperMissing.call(depth0, "i18n", "inbox.deprecatedMoreDetails", options)))
    + "</a>\n</div>\n<div class=\"thread-list-region\"></div>\n<div class=\"thread-body-container\">\n<div class=\"thread-top-bar\">\n<div class=\"actions-region\"></div>\n<div class=\"recipient-region\"></div>\n<div class=\"participants-region\"></div>\n<div class=\"preloader-region\"></div>\n</div>\n<div class=\"thread-body-region\"></div>\n</div>\n</div>\n</div>\n";
  return buffer;
  });

this["Templates"]["profile/activity_feed/activity-feed-empty"] = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;


  buffer += "<div class=\"kx-feed-empty-indicator\">"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "activityFeed.empty", options) : helperMissing.call(depth0, "i18n", "activityFeed.empty", options)))
    + "</div>\n";
  return buffer;
  });

this["Templates"]["profile/activity_feed/activity-feed-item-brag"] = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, options, functionType="function", escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing;


  buffer += "<div class=\"kx-feed-item-thumbnail kx-gradient-dark-light-grey\">\n</div>\n<div class=\"kx-feed-item-content\">\n<div class=\"kx-feed-item-delete\"></div>\n<span class=\"kx-feed-item-appname kx-link\" data-id=\"";
  if (helper = helpers.appId) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.appId); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\">\n";
  if (helper = helpers.appTitle) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.appTitle); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\n</span>\n<div class=\"kx-feed-item-text\">\n<span class=\"kx-feed-item-username kx-link\" id=\"";
  if (helper = helpers.kxid) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.kxid); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\">\n";
  if (helper = helpers.username) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.username); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "&nbsp;\n</span>\n"
    + escapeExpression((helper = helpers.escapeUrlize || (depth0 && depth0.escapeUrlize),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.text), options) : helperMissing.call(depth0, "escapeUrlize", (depth0 && depth0.text), options)))
    + "\n</div>\n<div class=\"kx-feed-item-time\">\n<time class=\"timeago\" datetime=\"";
  if (helper = helpers.updatedAt) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.updatedAt); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\"></time>\n</div>\n<div class=\"kx-feed-item-comments\"></div>\n</div>\n";
  return buffer;
  });

this["Templates"]["profile/activity_feed/activity-feed-item-default"] = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, options, functionType="function", escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing;


  buffer += "<div class=\"kx-feed-item-thumbnail kx-gradient-dark-light-grey\">\n</div>\n<div class=\"kx-feed-item-content\">\n<div class=\"kx-feed-item-delete\"></div>\n<span class=\"kx-feed-item-username kx-link\" id=\"";
  if (helper = helpers.kxid) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.kxid); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\">\n";
  if (helper = helpers.username) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.username); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\n</span>\n<div class=\"kx-feed-item-text prewrap\">";
  stack1 = (helper = helpers.escapeUrlize || (depth0 && depth0.escapeUrlize),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.text), options) : helperMissing.call(depth0, "escapeUrlize", (depth0 && depth0.text), options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "</div>\n<div class=\"kx-feed-item-time\">\n<time class=\"timeago\" datetime=\"";
  if (helper = helpers.updatedAt) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.updatedAt); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\"></time>\n</div>\n<div class=\"kx-feed-item-comment-btn\">comment</div>\n<div class=\"kx-feed-item-comments\"></div>\n</div>\n";
  return buffer;
  });

this["Templates"]["profile/activity_feed/activity-feed-item-forum-post"] = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, options, functionType="function", escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing;


  buffer += "<div class=\"kx-feed-item-thumbnail kx-gradient-dark-light-grey\">\n</div>\n<div class=\"kx-feed-item-content\">\n<div class=\"kx-feed-item-delete\"></div>\n<div class=\"kx-feed-item-username kx-link\" id=\"";
  if (helper = helpers.kxid) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.kxid); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\">\n";
  if (helper = helpers.username) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.username); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\n</div>\n<div class=\"kx-feed-item-title kx-link\">\n<a href=\"";
  if (helper = helpers.url) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.url); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\">";
  if (helper = helpers.title) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.title); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</a>\n</div>\n<div class=\"kx-feed-item-text\">"
    + escapeExpression((helper = helpers.nl2br || (depth0 && depth0.nl2br),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.text), options) : helperMissing.call(depth0, "nl2br", (depth0 && depth0.text), options)))
    + "</div>\n<span class=\"kx-feed-item-url\">\n<a href=\"";
  if (helper = helpers.url) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.url); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\">"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "activityFeed.goToForums", options) : helperMissing.call(depth0, "i18n", "activityFeed.goToForums", options)))
    + "</a>\n</span>\n<span class=\"kx-feed-item-time\">\n<time class=\"timeago\" datetime=\"";
  if (helper = helpers.updatedAt) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.updatedAt); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\"></time>\n</span>\n</div>\n";
  return buffer;
  });

this["Templates"]["profile/activity_feed/activity-feed-item-forum-reply"] = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";


  buffer += "<div class=\"kx-feed-item-thumbnail kx-gradient-dark-light-grey\">\n</div>\n<div class=\"kx-feed-item-content\">\n<div class=\"kx-feed-item-delete\"></div>\n<div class=\"kx-feed-item-usernames\">\n"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{
    'from': ((depth0 && depth0.usernameWhoCommented)),
    'fromId': ((depth0 && depth0.usernameWhoCommentedId)),
    'to': ((depth0 && depth0.usernameWhoPosted)),
    'toId': ((depth0 && depth0.usernameWhoPostedId))
  },data:data},helper ? helper.call(depth0, "activityFeed.commentedOn", options) : helperMissing.call(depth0, "i18n", "activityFeed.commentedOn", options)))
    + "\n</div>\n<div class=\"kx-feed-item-title\">\n<a href=\"";
  if (helper = helpers.originalPostUrl) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.originalPostUrl); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\" class=\"kx-link\">";
  if (helper = helpers.originalPostTitle) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.originalPostTitle); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</a>\n</div>\n<div class=\"kx-feed-item-text prewrap\">"
    + escapeExpression((helper = helpers.escapeUrlize || (depth0 && depth0.escapeUrlize),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.comment), options) : helperMissing.call(depth0, "escapeUrlize", (depth0 && depth0.comment), options)))
    + "</div>\n<div class=\"kx-feed-item-time\">\n<time class=\"timeago\" datetime=\"";
  if (helper = helpers.updatedAt) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.updatedAt); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\"></time>\n</div>\n</div>\n";
  return buffer;
  });

this["Templates"]["profile/activity_feed/activity-feed-item-gift"] = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, options, functionType="function", escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing;


  buffer += "<div class=\"kx-feed-item-thumbnail kx-gradient-dark-light-grey\">\n</div>\n<div class=\"kx-feed-item-content\">\n<div class=\"kx-feed-item-delete\"></div>\n<span class=\"kx-feed-item-username kx-link\" id=\"";
  if (helper = helpers.kxid) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.kxid); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\">\n";
  if (helper = helpers.username) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.username); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "&nbsp;\n</span>\n<span class=\"kx-feed-item-text\">"
    + escapeExpression((helper = helpers.escapeUrlize || (depth0 && depth0.escapeUrlize),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.text), options) : helperMissing.call(depth0, "escapeUrlize", (depth0 && depth0.text), options)))
    + "</span>\n<div class=\"kx-feed-item-time\">\n<time class=\"timeago\" datetime=\"";
  if (helper = helpers.updatedAt) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.updatedAt); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\"></time>\n</div>\n<div class=\"kx-feed-item-comments\"></div>\n</div>\n";
  return buffer;
  });

this["Templates"]["profile/activity_feed/activity-feed-item-new-friend"] = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, self=this, functionType="function";

function program1(depth0,data) {
  
  var buffer = "", stack1, helper, options;
  buffer += "\n"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{
    'friendA': (((stack1 = ((stack1 = (depth0 && depth0.friendA)),stack1 == null || stack1 === false ? stack1 : stack1.hydrated)),stack1 == null || stack1 === false ? stack1 : stack1.username)),
    'friendAId': (((stack1 = ((stack1 = (depth0 && depth0.friendA)),stack1 == null || stack1 === false ? stack1 : stack1.hydrated)),stack1 == null || stack1 === false ? stack1 : stack1.id)),
    'friendB': (((stack1 = ((stack1 = (depth0 && depth0.friendB)),stack1 == null || stack1 === false ? stack1 : stack1.hydrated)),stack1 == null || stack1 === false ? stack1 : stack1.username)),
    'friendBId': (((stack1 = ((stack1 = (depth0 && depth0.friendB)),stack1 == null || stack1 === false ? stack1 : stack1.hydrated)),stack1 == null || stack1 === false ? stack1 : stack1.id))
  },data:data},helper ? helper.call(depth0, "activityFeed.nowFriendsCurrentUser", options) : helperMissing.call(depth0, "i18n", "activityFeed.nowFriendsCurrentUser", options)))
    + "\n";
  return buffer;
  }

function program3(depth0,data) {
  
  var buffer = "", stack1, helper, options;
  buffer += "\n"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{
    'friendA': (((stack1 = ((stack1 = (depth0 && depth0.friendA)),stack1 == null || stack1 === false ? stack1 : stack1.hydrated)),stack1 == null || stack1 === false ? stack1 : stack1.username)),
    'friendAId': (((stack1 = ((stack1 = (depth0 && depth0.friendA)),stack1 == null || stack1 === false ? stack1 : stack1.hydrated)),stack1 == null || stack1 === false ? stack1 : stack1.id)),
    'friendB': (((stack1 = ((stack1 = (depth0 && depth0.friendB)),stack1 == null || stack1 === false ? stack1 : stack1.hydrated)),stack1 == null || stack1 === false ? stack1 : stack1.username)),
    'friendBId': (((stack1 = ((stack1 = (depth0 && depth0.friendB)),stack1 == null || stack1 === false ? stack1 : stack1.hydrated)),stack1 == null || stack1 === false ? stack1 : stack1.id))
  },data:data},helper ? helper.call(depth0, "activityFeed.nowFriendsNotCurrentUser", options) : helperMissing.call(depth0, "i18n", "activityFeed.nowFriendsNotCurrentUser", options)))
    + "\n";
  return buffer;
  }

  buffer += "<div class=\"kx-feed-item-thumbnail kx-gradient-dark-light-grey\">\n</div>\n<div class=\"kx-feed-item-content\">\n<div class=\"kx-feed-item-delete\"></div>\n<div class=\"kx-feed-item-text\">\n";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.currentUserFeed), {hash:{},inverse:self.program(3, program3, data),fn:self.program(1, program1, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n</div>\n<div class=\"kx-feed-item-time\">\n<time class=\"timeago\" datetime=\"";
  if (helper = helpers.updatedAt) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.updatedAt); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\"></time>\n</div>\n</div>\n";
  return buffer;
  });

this["Templates"]["profile/activity_feed/activity-feed-item-ranked"] = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, options, functionType="function", escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing;


  buffer += "<div class=\"kx-feed-item-thumbnail kx-gradient-dark-light-grey\">\n</div>\n<div class=\"kx-feed-item-content\">\n<div class=\"kx-feed-item-delete\"></div>\n<span class=\"kx-feed-item-appname kx-link\" data-id=\"";
  if (helper = helpers.appId) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.appId); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\">\n";
  if (helper = helpers.appTitle) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.appTitle); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n</span>\n<div class=\"kx-feed-item-text\">\n"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "activityFeed.congratulations", options) : helperMissing.call(depth0, "i18n", "activityFeed.congratulations", options)))
    + "\n<span class=\"kx-feed-item-username kx-link\" id=\"";
  if (helper = helpers.kxid) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.kxid); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\">\n&nbsp;";
  if (helper = helpers.username) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.username); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "&nbsp;\n</span>\n";
  if (helper = helpers.text) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.text); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\n</div>\n<div class=\"kx-feed-item-time\">\n<time class=\"timeago\" datetime=\"";
  if (helper = helpers.updatedAt) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.updatedAt); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\"></time>\n</div>\n<div class=\"kx-feed-item-comments\"></div>\n</div>\n";
  return buffer;
  });

this["Templates"]["profile/activity_feed/activity-feed-items"] = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<ul class=\"kx-feed-item-list\"></ul>\n";
  });

this["Templates"]["profile/activity_feed/activity-feed-loading"] = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;


  buffer += "<div class=\"kx-feed-loading-indicator kx-gradient-dark-light-grey\">\n"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "activityFeed.loading", options) : helperMissing.call(depth0, "i18n", "activityFeed.loading", options)))
    + "\n</div>\n";
  return buffer;
  });

this["Templates"]["profile/activity_feed/activity-feed-permission-denied"] = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;


  buffer += "<div class=\"kx-feed-permissions-indicator kx-gradient-dark-light-grey\">\n"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "activityFeed.noPermission", options) : helperMissing.call(depth0, "i18n", "activityFeed.noPermission", options)))
    + "\n</div>\n";
  return buffer;
  });

this["Templates"]["profile/activity_feed/activity-feed"] = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;


  buffer += "<div id=\"message-input-region\"></div>\n<div id=\"edit-privacy-region\"></div>\n<div id=\"feed-scroll\">\n<div id=\"feed-region\"></div>\n<div class=\"kx-loading-region\">\n<div class=\"kx-more-loading\"></div>\n<div class=\"kx-load-more-btn kx-link\">"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "activityFeed.seeMorePostsBtn", options) : helperMissing.call(depth0, "i18n", "activityFeed.seeMorePostsBtn", options)))
    + "</div>\n</div>\n</div>\n";
  return buffer;
  });

this["Templates"]["profile/activity_feed/feed-item-image"] = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, functionType="function", escapeExpression=this.escapeExpression;


  buffer += "<div class=\"kx-feed-item-image\" style=\"background-image: url('";
  if (helper = helpers.url) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.url); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "');\"></div>\n";
  return buffer;
  });

this["Templates"]["profile/activity_feed/system-notifications"] = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, options, functionType="function", escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing;


  buffer += "<div class=\"kx-feed-item-thumbnail kx-gradient-dark-light-grey\">\n</div>\n<div class=\"kx-feed-item-content\">\n<div class=\"kx-feed-item-delete\"></div>\n<div class=\"kx-feed-item-text\">";
  if (helper = helpers.content) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.content); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "</div>\n<div class=\"kx-feed-item-url\">\n<a href=\"";
  if (helper = helpers.url) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.url); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\">"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{},data:data},helper ? helper.call(depth0, "activityFeed.moreInformation", options) : helperMissing.call(depth0, "i18n", "activityFeed.moreInformation", options)))
    + "</a>\n</div>\n<div class=\"kx-feed-item-time\">\n<time class=\"timeago\" datetime=\"";
  if (helper = helpers.publishAt) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.publishAt); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\"></time>\n</div>\n</div>\n";
  return buffer;
  });